<template>
	<div>
		Hello World
	</div>
</template>

<script>
export default {

}
</script>

<style scoped>

</style>
